<template>
  <div class="relative">
    <div class="h-8 flex">
      <input
        v-model="query"
        type="text"
        placeholder="Пошук товарів"
        class="border border-black border-r-0 p-2 text-sm outline-none w-full rounded-none"
      >
      <div class="bg-[#f5d3d2] h-8 w-8 flex justify-center items-center">
        <fa v-if="query.length === 0" icon="fa-solid fa-magnifying-glass" class="text-black" />
        <button v-else @click.stop="query = ''">
          <fa icon="fa-solid fa-xmark" />
        </button>
      </div>
    </div>
    <div 
      :class="{ 'max-h-[288px]': sidebar, 'absolute shadow-md max-h-52': !sidebar }"
      class="bg-white overflow-auto w-full">
      <NuxtLink
        v-for="product in productsFiltered"
        :to="`/shop/product/${product?.url}`"
        class="grid grid-cols-[50px_1fr] justify-center relative px-2 py-1 hover:text-[#f5d3d2] items-center gap-2"
        @click="query = ''; emit('navigate')"
      >
        <img
          :src="product.images[0].url"
          :alt="product.name"
          class="h-16 m-auto object-contain"
        >
        <span class="text-xs uppercase">
          {{ product.name }}
        </span>
      </NuxtLink>
      <div v-if="query.length > 0 && productsFiltered.length === 0" class="text-xs uppercase mt-3 px-2 py-1 text-center">
        На ваш запит нічого не знайдено.
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'

const props = defineProps({
  sidebar: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['navigate'])

const store = useStore()
const query = ref('')
const productsFiltered = ref([])

watch(query,
  (q) => {
    if (q === '') {
      productsFiltered.value = []
    } else {
      productsFiltered.value = store.products.filter((product) => product.name.toLowerCase().match(query.value.toLowerCase()))
    }
  }
)

</script>
