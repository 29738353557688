<template>
  <header class="fixed w-full z-10">
    <Transition name="slide-sidebar">
      <Sidebar
        class="lg:hidden"
        v-show="isSideBar"
        @hide="isSideBar = false"
        v-click-away="() => isSideBar ? isSideBar = false : null"
        :menu-list="mainCategories"
      />
    </Transition>
    <TopBar/>
    <div class="lg:h-24 py-2 bg-white w-full border-b border-black flex items-center px-6 justify-between">
      <div class="flex items-center">
        <button
          @click="isSideBar = !isSideBar"
          class="text-2xl mr-5 lg:hidden"
        >
          <fa icon="fa-solid fa-bars" />
        </button>
        <NuxtLink
          to="/"
          class="w-10 lg:w-20"
        >
          <img
            class="w-full"
            src="~/assets/logo.svg"
            alt="Логотип"
            width="40"
            height="40"
          >
        </NuxtLink>
      </div>
      <div class="flex items-center w-full">
        <ul class="hidden lg:flex ml-auto gap-7 items-center">
          <li>
            <div
              @click="isShowMenu = true"
              class="uppercase hover:text-[#f5d3d2] relative cursor-pointer "
            >
              Магазин
            </div>
            <HeaderMenu
              v-if="isShowMenu"
              @hide="isShowMenu = false"
              v-click-away="() => isShowMenu ? isShowMenu = false : null"
              :mainCategories="mainCategories"
            />
          </li>
          <li v-if="store.saleProducts.length > 0 && !store.site.globalSale">
            <NuxtLink
              class="uppercase hover:text-[#f5d3d2]"
              to="/sale"
            >
              Sale
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              class="uppercase hover:text-[#f5d3d2]"
              to="/cooperation"
            >
              Співпраця з нами
            </NuxtLink>
          </li>
          <li>
            <OnlineDikidi class="rounded-md" />
          </li>
        </ul>
        <Search class="mx-8 w-full lg:w-60 hidden sm:block" />
        <div class="flex items-center shrink-0 ml-auto">
          <NuxtLink
            :to="store.isLogedIn ? '/profile/information' : '/sign-in'"
            class="mr-5 hover:text-[#f5d3d2] text-lg"
          >
            <ClientOnly>
              <span class="mr-3">{{ store.user.firstname }}</span>
            </ClientOnly>
            <fa icon="fa-solid fa-user"  />
          </NuxtLink>
          <NuxtLink
            to="/cart"
            class="text-lg relative"
          >
            <fa class="hover:text-[#f5d3d2]" icon="fa-solid fa-cart-shopping"  />
            <ClientOnly>
              <span
                v-if="cartStore.total.items > 0"
                class="absolute rounded-full -top-3 -right-3 bg-[#f5d3d2] text-sm font-bold text-center w-5 h-5"
              >
                {{ cartStore.total.items }}
              </span>
            </ClientOnly>
          </NuxtLink>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useFirestore, useCollection } from 'vuefire'
import { collection } from 'firebase/firestore'
import { useStore } from '@/stores/main'
import { useCartStore } from '@/stores/cart'
import OnlineDikidi from './OnlineDikidi.vue';

const db = useFirestore()
const store = useStore()
const cartStore = useCartStore()
const isSideBar = ref(false)
const isShowMenu = ref(false)
const mainCategories = ref([])
const categories = useCollection(collection(db, 'categories'))

mainCategories.value = categories.value.filter(item => !item.parentId || item.parentId  === '0').map(item => ({ subcategories: [], id: item.id, ...item }))

categories.value.forEach((item) => {
  if (item.parentId && item.parentId  !== '0') {
    const index = mainCategories.value.findIndex(c => c.id === item.parentId)
    mainCategories.value[index].subcategories.push(item)
  }
})

</script>
<style>
.shop:hover .menu {
  display: block;
}
.slide-sidebar-enter-active {
  transition: all 0.3s ease-out;
}

.slide-sidebar-leave-active {
  transition: all 0.3s;
}

.slide-sidebar-enter-from,
.slide-sidebar-leave-to {
  transform: translateX(-400px);
  opacity: 0;
}
</style>