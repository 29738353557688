<template>
  <div class="fixed top-0 left-0 overflow-auto bg-white z-10 h-screen w-80">
    <div class="flex items-center justify-between px-4 mb-5 pt-2">
      <NuxtLink
        @click="emit('hide')"
        to="/"
        class="w-20"
      >
        <img
          class="w-full"
          src="~/assets/logo.svg"
          alt="Логотип"
          width="80"
          height="80"
        >
      </NuxtLink>
      <button @click="emit('hide')" class="text-2xl hover:text-[#f5d3d2]">
        <fa icon="fa-solid fa-xmark" />
      </button>
    </div>
    <div>
      <div class="px-7 mb-3">
        <Search :sidebar="true" @navigate="emit('hide')" class="w-full" />
      </div>
      <ul>
        <li>
          <span
            @click="isShowShop = !isShowShop"
            class="px-7 py-3 flex items-center justify-between cursor-pointer hover:text-[#f5d3d2]"
          >
            Магазин
            <fa icon="fa-solid fa-chevron-down" class="transition-all" :class="{ 'rotate-180': isShowShop}" />
          </span>
          <Transition name="slide-fade">
            <div v-if="isShowShop">
              <ul
                class="bg-[#f3f4f6]"
                v-for="category in mobileMenu"
                :key="category._id"
              >
                <li
                  @click="category.isVisibale = !category.isVisibale"
                  class="text-black px-7 py-3 flex items-center justify-between cursor-pointer"
                >
                  {{ category.name }}
                  <fa icon="fa-solid fa-chevron-down" class="transition-all" :class="{ 'rotate-180': category.isVisibale}"  />
                </li>
                <Transition name="slide-fade">
                  <ul
                    class="bg-[#e5e7eb]"
                    v-if="category.isVisibale"
                  >
                    <NuxtLink
                      v-for="subcategory in category.subcategories"
                      :key="subcategory._id"
                      :to="`/shop/${subcategory.url}`"
                      @click="emit('hide')"
                      class="text-black px-7 py-3 block hover:underline"
                    >
                      {{ subcategory.name }}
                    </NuxtLink>
                  </ul>
                </Transition>
              </ul>
            </div>
          </Transition>
        </li>
        <li>
          <NuxtLink
            v-if="store.saleProducts.length > 0 && !store.site.globalSale"
            class="block px-7 py-3 cursor-pointer hover:text-[#f5d3d2]"
            to="/sale"
            @click="emit('hide')"
          >
            Sale
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            class="block px-7 py-3 cursor-pointer hover:text-[#f5d3d2]"
            to="/cooperation"
            @click="emit('hide')"
          >
            Співпраця з нами
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            class="block px-7 py-3 cursor-pointer hover:text-[#f5d3d2]"
            to="/about-us"
            @click="emit('hide')"
          >
            Про нас
          </NuxtLink>
        </li>
        <li>
          <OnlineDikidi />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'

const store = useStore()

const props = defineProps({
  menuList: {
    type: Array,
    default: () => []
  },
})

const emit = defineEmits(['hide'])
const mobileMenu = ref(props.menuList)
const isShowShop = ref(false)

</script>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>