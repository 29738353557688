<template>
  <footer class="md:flex px-6 justify-between py-5 gap-3 bg-black">
    <div class="md:w-1/2 mb-6 md:mb-0">
      <NuxtLink to="/">
        <img
          class="m-auto"
          src="~/assets/logo-white.svg"
          alt="Логотип"
          width="96"
          height="96"
        >
      </NuxtLink>
    </div>
    <div class="mb-6 md:mb-0 md:w-1/2">
      <h3 class="font-bold text-sm uppercase mb-4 text-white text-center md:text-left">Меню</h3>
      <ul class="list-none text-center md:text-left">
        <li>
          <NuxtLink
            to="/about-us"
            class="hover:underline hover:text-[#f5d3d2] text-white font-thin"
          >
            Про нас
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="/return-goods"
            class="hover:underline hover:text-[#f5d3d2] text-white font-thin"
          >
            Обмін та повернення
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="/contact-us"
            class="hover:underline hover:text-[#f5d3d2] text-white font-thin"
          >
            Зв'язатись з нами
          </NuxtLink>
        </li>
      </ul>
    </div>
    <div class="mb-6 md:mb-0 md:w-1/2">
      <h3 class="font-bold text-sm uppercase mb-4 text-white text-center md:text-left">Контакти</h3>    
      <div class="flex justify-center md:justify-normal">
        <a
          v-if="store.site.instagram"
          target="_blank"
          :href="store.site.instagram"
        >
          <fa class="text-2xl mr-2.5 text-white hover:text-[#f5d3d2]" icon="fa-brands fa-instagram" />
        </a>
        <a
          v-if="store.site.facebook"
          target="_blank"
          :href="store.site.facebook"
        >
          <fa class="text-2xl mr-2.5 text-white hover:text-[#f5d3d2]" icon="fa-brands fa-square-facebook" />
        </a>
        <a
          v-if="store.site.youtube"
          target="_blank"
          :href="store.site.youtube"
        >
          <fa class="text-2xl text-white hover:text-[#f5d3d2]" icon="fa-brands fa-youtube" />
        </a>
      </div>
      <a
        class="block hover:underline hover:text-[#f5d3d2] text-white font-thin text-center md:text-left"
        :href="`tel:${store.site.phone}`"
      >
        {{ store.site.phone }}
      </a>
      <a
        class="block hover:underline hover:text-[#f5d3d2] text-white font-thin text-center md:text-left"
        :href="`mailto:${store.site.email}`"
      >
        {{ store.site.email }}
      </a>
    </div>
  </footer>
</template>
  
<script setup>
import { useStore } from "@/stores/main"

const store = useStore()
</script>
