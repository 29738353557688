<template>
  <div class="absolute w-full bg-[#d9dadc] z-10 left-0 shadow-md p-5 mt-[37px]">
    <div class="flex gap-2 justify-between">
      <div
        v-for="category in mainCategories"
        :key="category._id"
      >
        <template v-if="!category.hidden">
          <p class="leading-normal font-bold text-black uppercase">{{ category.name }}</p>
          <ul class="mt-4">
            <NuxtLink
              v-for="subcategory in category.subcategories"
              :key="subcategory._id"
              :to="`/shop/${subcategory.url}`"
              @click="emit('hide')"
              class="leading-normal text-black font-normal normal-case block hover:text-[#fff]"
            >
              {{ subcategory.name }}
            </NuxtLink>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  mainCategories: {
    type: Array,
    reqired: true
  },
})

const emit = defineEmits(['hide'])
</script>
<style>
.shop:hover .menu {
  display: block;
}
.slide-sidebar-enter-active {
  transition: all 0.3s ease-out;
}

.slide-sidebar-leave-active {
  transition: all 0.3s;
}

.slide-sidebar-enter-from,
.slide-sidebar-leave-to {
  transform: translateX(-400px);
  opacity: 0;
}
</style>