<template>
  <div v-if="store.site.topBarText" class="w-full bg-black text-center text-[#f5d3d2] flex items-center justify-center py-1 px-3 text-sm xl:text-base">
    {{ store.site.topBarText }} 
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
const store = useStore()
  
</script>
